<template>
  <div>
    <div class="header">
      <div
        @click="backToMenu()"
        class="btn-img">
        <img src="@/assets/icon-menu.png"/>
      </div>
    </div>
    <div class="page-title">Lesson Time Targets</div>
    <div class="flex mt-2 center">
      <div class="lessons">
        <div v-for="lesson in lessons"
          class="lesson"
          :class="[isSelected(lesson.id) ? 'selected': '']"
          @click="selectLesson(lesson.id)"
          :key="lesson.id">
          <div class="col-1 underline">Step {{lesson.step}}</div>
          <div class="col-1 underline">Lesson {{lesson.sort}}</div>          
          <div class="col-1 underline">{{lesson.name}}</div>
          <div class="col-3 underline">{{lesson.title}}</div>
          <div class="col-1">{{lesson.timeLimit}} min</div>
        </div>
      </div>
      <div
        v-if="selectedLesson"
        class="arrow">
        <img src="@/assets/arrow-right.png" />
      </div>
      <div
        v-if="selectedLesson"
        class="time-config">
        <div
          class="row"
          v-for="time in timeArray"
          :value="time"
          :class="[isTimeSelected(time) ? 'selected': '']"
          @click="selectTime(time)"
          :key="time">
          {{time}} min
        </div>
      </div>
    </div>
    <button
      @click="saveTimeLimit"
      :disabled="!selectedLesson"
      class="btn-bg mt-2">
      Set Time Targets
    </button>
  </div>
</template>

<script>
export default {
  name: 'TimeLimitConfig',
  props: {
  },
  data() {
    return {
      selectedLesson: null,
      lessons: [],
      timeArray: [
        5,6,7,8,9,10,11,12,
      ]
    }
  },
  computed: {
    isSelected() {
      return id => id === this.selectedLesson?.id
    },
    isTimeSelected() {
      return time => time === this.selectedLesson?.timeLimit
    },
  },
  created() {
    const step = this.$route.query.step
    const forms = this.$route.query.forms
    this.$api.get(`/tests?type=lesson&step=${step}&forms=${forms}`).then(res => {
      this.lessons = res.data.tests
    })
  },
  methods: {
    selectLesson(id) {
      this.selectedLesson = this.lessons.find( l => l.id === id)      
    },
    selectTime(time) {
      this.selectedLesson.timeLimit = time      
    },
    saveTimeLimit() {
      this.$api.patch('/test', {
        testId: this.selectedLesson.id,
        timeLimit: this.selectedLesson.timeLimit,
      }).then(res => {
        console.log('update success:' + res)
      })

    },
    backToMenu() {
      this.$router.push('/manager/menu')
    },
  }
}
</script>

<style lang="scss" scoped>
.lessons {
  background: white;
  border-radius: 5px;
  width: 65%;
  height: 60vh;
  overflow-y: scroll;
  box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);
  .underline {
    text-decoration: underline;
  }
  .lesson {
    border-bottom: 1px solid rgb(241, 216, 216);
    padding: 10px 5px;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    padding-left: 20px;
    display: flex;
  }
  .lesson:hover, .selected {
    background: rgb(243, 233, 233);
    cursor: pointer;
  }
}
.arrow {
  img {
    width: 60px;
  }
  margin: 0 20px;
}
.time-config {
  width: 100px;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  background: white;
  box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);
  .row {
    text-align: left;
    padding: 5px;
  }
  .row:hover, .selected {
    background: rgb(243, 233, 233);
    cursor: pointer;
  }
}
</style>

